import { useState } from 'react';

import 'core-js/actual';
import { listen } from '@ledgerhq/logs';
import AppBtc from '@ledgerhq/hw-app-btc';
import TransportWebUSB from '@ledgerhq/hw-transport-webusb';
import { notifyError } from 'notify';
import { getDerivationPath } from 'utils';
import { NetworkType, ScriptTypes } from 'enums';

function useLedger() {
  const [ledger, setLedger] = useState<any>();
  const [status, setStatus] = useState('disconnected');

  const connect = async () => {
    const transport = await TransportWebUSB.create();
    listen(log => console.log(log));
    let app;
    let data;
    try {
      app = new AppBtc({ transport, currency: 'bitcoin' });
      const singleSigPath = getDerivationPath(
        ScriptTypes.P2WPKH,
        NetworkType.MAINNET
      );
      data = await app.getWalletXpub({
        path: singleSigPath,
        xpubVersion: 76067358,
      });
    } catch (err) {
      console.log('Mainnet connection failed, trying testnet..');
    }
    if (!data) {
      try {
        app = new AppBtc({ transport, currency: 'bitcoin_testnet' });
        const singleSigPath = getDerivationPath(
          ScriptTypes.P2WPKH,
          NetworkType.TESTNET
        );
        data = await app.getWalletXpub({
          path: singleSigPath,
          xpubVersion: 70617039,
        });
      } catch (err) {
        handleError(err);
      }
    }
    if (data) {
      setLedger(transport);
      setStatus('initialized');
    }
  };
  return { ledger, status, connect };
}

export const handleError = error => {
  if (error.message === 'Ledger device: UNKNOWN_ERROR (0x6511)') {
    notifyError('Please open the Bitcoin app on your Ledger and try again.');
  } else if (error.message === 'Ledger device: UNKNOWN_ERROR (0x6a82)') {
    notifyError(
      'Please make sure the right Bitcoin app is open on your Ledger'
    );
  } else if (error.message === 'Ledger device: Locked device (0x5515)') {
    notifyError('Please unlock your Ledger');
  } else {
    console.log({ error });
    notifyError('Something went wrong. Please try again.');
  }
};

export default useLedger;
