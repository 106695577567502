import { constants, getKeypathFromString } from 'bitbox02-api';
import { NetworkType, ScriptTypes } from 'enums';
import { getDerivationPath } from 'utils';
const userApproval = true; // display user approval dialog on device
const simpleType = constants.messages.BTCScriptConfig_SimpleType.P2WPKH;

const getXpub = async (transport, network) => {
  const singleSigPath = getDerivationPath(ScriptTypes.P2WPKH, network);
  const multiSigPath = getDerivationPath(ScriptTypes.P2WSH, network);
  const coin =
    network === NetworkType.MAINNET
      ? constants.messages.BTCCoin.BTC
      : constants.messages.BTCCoin.TBTC;
  const xpubType =
    network === NetworkType.MAINNET
      ? constants.messages.BTCXPubType.XPUB
      : constants.messages.BTCXPubType.TPUB;

  const singleSigXpub = await transport.btcXPub(
    coin,
    getKeypathFromString(singleSigPath),
    xpubType,
    userApproval
  );
  const multiSigXpub = await transport.btcXPub(
    coin,
    getKeypathFromString(multiSigPath),
    xpubType,
    userApproval
  );
  return {
    singleSigPath,
    multiSigPath,
    singleSigXpub,
    multiSigXpub,
    xfp: 'ABCDEF',
  };
};

const getAccount = (accountData, network) => {
  const { keypathAccountDerivation, ourXPubIndex, threshold, xpubs } =
    accountData;
  const coin =
    network === NetworkType.MAINNET
      ? constants.messages.BTCCoin.BTC
      : constants.messages.BTCCoin.TBTC;
  return {
    keypathAccount: getKeypathFromString(keypathAccountDerivation),
    ourXPubIndex,
    threshold,
    xpubs,
    coin,
  };
};

const registerMultisig = async (transport, accountData, network) => {
  const getName = async () =>
    prompt('Please provide a name for the multisig account (max 30 chars)');
  const account = getAccount(accountData, network);
  return transport.btcMaybeRegisterScriptConfig(account, getName);
};

const signTransaction = async (transport, data, network) => {
  try {
    const {
      isMultisig,
      derivationPath,
      inputs,
      outputs,
      walletConfig,
      version,
      locktime,
    } = data;
    const account = isMultisig ? getAccount(walletConfig, network) : null;
    const coin =
      network === NetworkType.MAINNET
        ? constants.messages.BTCCoin.BTC
        : constants.messages.BTCCoin.TBTC;
    const keypathAccount = getKeypathFromString(derivationPath);
    const formattedInputs = inputs.map(input => {
      input.prevOutHash = new Uint8Array(Buffer.from(input.prevOutHash, 'hex'));
      input.prevTx.inputs = input.prevTx.inputs.map(prevInput => {
        prevInput.prevOutHash = new Uint8Array(
          Buffer.from(prevInput.prevOutHash, 'hex')
        );
        prevInput.signatureScript = new Uint8Array(
          Buffer.from(prevInput.signatureScript, 'hex')
        );
        return prevInput;
      });
      input.prevTx.outputs = input.prevTx.outputs.map(prevOutput => {
        prevOutput.pubkeyScript = new Uint8Array(
          Buffer.from(prevOutput.pubkeyScript, 'hex')
        );
        return prevOutput;
      });
      return input;
    });
    const formattedOutputs = outputs.map(output => {
      if (!output.ours) {
        output.type =
          Buffer.from(output.payload, 'hex').length === 20
            ? constants.messages.BTCOutputType.P2WPKH
            : constants.messages.BTCOutputType.P2WSH;
        output.payload = new Uint8Array(Buffer.from(output.payload, 'hex'));
        return output;
      }
      return output;
    });

    const signatures = await (isMultisig
      ? transport.btcSignMultisig(
          account,
          formattedInputs,
          formattedOutputs,
          version,
          locktime
        )
      : transport.btcSignSimple(
          coin,
          simpleType,
          keypathAccount,
          formattedInputs,
          formattedOutputs,
          version,
          locktime
        ));

    const signaturesHex = signatures.map(sig =>
      Buffer.from(sig).toString('hex')
    );
    return signaturesHex;
  } catch (e) {
    console.log(e);
  }
};

export { getXpub, registerMultisig, signTransaction };
