const Loader = () => (
  <div className='flex items-center justify-center space-x-2'>
    <div className='w-4 h-4 rounded-full animate-pulse bg-black'></div>
    <div className='w-4 h-4 rounded-full animate-pulse bg-black'></div>
    <div className='w-4 h-4 rounded-full animate-pulse bg-black'></div>
  </div>
);

function Primary({ onClick, children, loading = false }) {
  return loading ? (
    <Loader />
  ) : (
    <button
      onClick={onClick}
      className='p-4 px-8 m-4 font-bold text-sm rounded bg-gradient-to-r from-lightGreen to-darkGreen text-background'>
      {children}
    </button>
  );
}

export { Primary };
