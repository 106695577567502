import 'react-toastify/dist/ReactToastify.css';
import { getXpub, registerMultisig, signTransaction } from 'bridge/ledger';
import * as Button from 'components/Button';
import Screen from 'components/Screen';
import { channel } from 'socket';
import {
  LEDGER_SETUP,
  LEDGER_REGISTER,
  LEDGER_SIGN,
  SIGNED_TX,
  LEDGER_HEALTHCHECK,
  REGISTRATION_SUCCESS,
} from 'socket/constants';
import { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import useLedger from 'hooks/useLedger';
import { notifyInfo, notifySuccess } from 'notify';
import { LEDGER_STATUS_MAP } from '../constants';
import { SignerType } from 'enums';
import { decrypt, encrypt } from 'utils';

const NOTIFY_DELAY = 2000;
function Ledger({ room, network, encryptionKey }) {
  const { ledger, status, connect } = useLedger();
  const [sharingXpub, setSharing] = useState(false);
  const [checkingHealth, setCheckingHealth] = useState(false);
  const [registering, setRegistering] = useState(false);
  const [signing, setSigning] = useState(false);

  const sendDeviceData = async (isHealthCheck = false) => {
    isHealthCheck ? setCheckingHealth(true) : setSharing(true);
    const data = await getXpub(ledger, network);
    if (data) {
      channel.emit(isHealthCheck ? LEDGER_HEALTHCHECK : LEDGER_SETUP, {
        room,
        data: encrypt(JSON.stringify(data), encryptionKey),
      });
      isHealthCheck ? setCheckingHealth(false) : setSharing(false);
      notifySuccess(
        `Ledger details shared successfully! You should see the device ${
          isHealthCheck ? 'health updated' : 'added'
        } on the Keeper app now.`
      );
    }
  };

  const initiateRegistering = async () => {
    setRegistering(true);
    channel.emit(LEDGER_REGISTER, { room });
  };

  useEffect(() => {
    if (ledger && network) {
      channel.on(LEDGER_REGISTER, async ({ data }) => {
        const decrypted = decrypt(data, encryptionKey);
        notifyInfo('Please verify the config on your Ledger...');
        const policy = await registerMultisig(ledger, decrypted, network);
        if (policy) {
          channel.emit(REGISTRATION_SUCCESS, {
            room,
            data: encrypt(
              JSON.stringify({ policy, signerType: SignerType.LEDGER }),
              encryptionKey
            ),
          });
          setRegistering(false);
          notifySuccess('Keeper vault registered successfully!');
        }
      });
    }
  }, [ledger, network]);

  const initiateSigning = () => {
    setSigning(true);
    channel.emit(LEDGER_SIGN, { room });
  };

  useEffect(() => {
    if (ledger && network) {
      channel.on(LEDGER_SIGN, async ({ data, room }) => {
        const decrypted = decrypt(data, encryptionKey);
        notifyInfo('Please verify the transaction on your Ledger...');
        const signedTx = await signTransaction(ledger, decrypted);
        if (signedTx) {
          channel.emit(SIGNED_TX, {
            room,
            data: encrypt(JSON.stringify(signedTx), encryptionKey),
          });
          setSigning(false);
          notifySuccess('Transaction signed successfully!');
        }
      });
    }
  }, [ledger, network]);

  return (
    <Screen>
      <div className='grid grid-cols-1 gap-10 h-full place-content-center place-items-center'>
        <div className='flex flex-col items-center justify-center h-20 w-80 bg-status rounded'>
          <p>{'Status'}</p>
          <p
            className={
              'text-' + LEDGER_STATUS_MAP[status] + ' font-bold'
            }>{`Device ${status[0].toUpperCase() + status.slice(1)}`}</p>
        </div>
        <div className='flex flex-col items-center'>
          {status !== 'initialized' ? (
            <div className='grid gap-4 grid-cols-1'>
              <Button.Primary onClick={connect}>
                Connect to Ledger
              </Button.Primary>
            </div>
          ) : null}
        </div>
        {status === 'initialized' ? (
          <div className='grid gap-4 grid-cols-1'>
            <Button.Primary
              onClick={() => sendDeviceData()}
              loading={sharingXpub}>
              SHARE XPUB
            </Button.Primary>
            <Button.Primary
              onClick={() => sendDeviceData(true)}
              loading={checkingHealth}>
              HEALTH CHECK
            </Button.Primary>
            <Button.Primary onClick={initiateRegistering} loading={registering}>
              REGISTER MULTISIG
            </Button.Primary>
            <Button.Primary onClick={initiateSigning} loading={signing}>
              SIGN PSBT
            </Button.Primary>
          </div>
        ) : null}
      </div>
      <ToastContainer
        position='top-center'
        autoClose={NOTIFY_DELAY}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover
        theme='light'
      />
    </Screen>
  );
}

export default Ledger;
