import { useState } from 'react';
import { BitBox02API, getDevicePath } from 'bitbox02-api';

function useBitbox() {
  const [bitbox02, setBitBox02] = useState<any>();
  const [status, setStatus] = useState('disconnected');
  const [pairingCode, setPairingCode] = useState('');

  const connect = async () => {
    try {
      const devicePath = await getDevicePath();
      const transport = new BitBox02API(devicePath);
      await transport.connect(
        pairingCode => {
          setPairingCode(pairingCode);
        },
        () =>
          new Promise((resolve, reject) => {
            resolve(true);
          }),
        attestationResult => {
          console.log('Attestation check:', attestationResult);
        },
        () => {
          alert('Device disconnected.'); // onLogout
        },
        status => {
          setStatus(status); // onStatusChange
        }
      );
      setBitBox02(transport);
    } catch (error) {
      console.log({ error });
      console.log(`Something went wrong :/`);
    }
  };
  return { bitbox02, status, pairingCode, connect };
}

export default useBitbox;
