import { useEffect, useState } from 'react';
import TrezorConnect, {
  DEVICE_EVENT,
  DeviceEventMessage,
} from '@trezor/connect-web';

const manifest = {
  email: 'developer@xyz.com',
  appUrl: 'http://your.application.com',
};

const initParams = {
  lazyLoad: true, // this param will prevent iframe injection until TrezorConnect.method will be called
  debug: true,
  manifest,
};
const INITIALISED_ERROR = 'Error: TrezorConnect has been already initialized';

function useTrezor() {
  const [status, setStatus] = useState<string>('disconnected');

  const init = async () => {
    try {
      await TrezorConnect.init(initParams);
    } catch (error) {
      if (error.toString() === INITIALISED_ERROR) {
        setStatus('connected');
      } else {
        console.log(error.toString());
      }
    }
  };

  const connect = async () => {
    const login = (await TrezorConnect.requestLogin({
      challengeHidden: '0123456789abcdef', // recheck the challenge here
      challengeVisual: 'Let Keeper connect?',
    })) as any;
    if (login.payload.error) {
      const { error } = login.payload as { error: string; code?: string };
      console.log(error);
    } else {
      setStatus('connected');
    }
  };

  useEffect(() => {
    TrezorConnect.on(DEVICE_EVENT, (event: DeviceEventMessage) => {
      if (event.type === 'device-disconnect') {
        setStatus('disconnected');
      } else if (event.type === 'device-connect') {
        setStatus('connected');
      } else {
        console.log(event.type);
      }
    });
  }, []);

  return { status, init, connect };
}

export default useTrezor;
