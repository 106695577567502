import TrezorConnect, {
  SignedTransaction,
  Unsuccessful,
  Success,
} from '@trezor/connect-web';
import { NetworkType, ScriptTypes } from 'enums';
import { getDerivationPath } from 'utils';
const userApproval = true; // display user approval dialog on device

const getXpub = async (network: NetworkType) => {
  const singleSigPath = getDerivationPath(ScriptTypes.P2WPKH, network);
  const multiSigPath = getDerivationPath(ScriptTypes.P2WSH, network);
  const coin = network === NetworkType.MAINNET ? 'btc' : 'test';
  const { payload } = await TrezorConnect.getPublicKey({
    bundle: [
      { coin, path: singleSigPath, showOnTrezor: userApproval },
      { coin, path: multiSigPath, showOnTrezor: userApproval },
    ],
  });
  return {
    singleSigPath,
    multiSigPath,
    singleSigXpub: payload[0].xpub,
    multiSigXpub: payload[1].xpub,
    xfp: 'FEDCBA',
  };
};

const signTransaction = async (
  data,
  network
): Promise<Unsuccessful | Success<SignedTransaction>> => {
  try {
    const coin = network === NetworkType.MAINNET ? 'btc' : 'test';
    data.coin = coin;
    return TrezorConnect.signTransaction(data);
  } catch (error) {
    return data;
  }
};

export { getXpub, signTransaction };
