import Tabs from 'components/Tab';
import { QRCodeSVG } from 'qrcode.react';
import { useEffect, useState } from 'react';
import Bitbox02 from 'screens/Bitbox02';
import Ledger from 'screens/Ledger';
import Trezor from 'screens/Trezor';
import { JOIN_CHANNEL, SET_NETWORK } from 'socket/constants';
import { channel } from 'socket';
import { hashString } from 'utils';
import KeeperLogo from 'assets/keeper_logo.png';
import Moto from 'assets/moto.png';

const crypto = require('crypto');

function App() {
  const active = localStorage.getItem('active');
  const [activeDevice, setActiveDevice] = useState(active || '1');
  const [network, setNetwork] = useState('');
  const random = crypto.randomBytes(32);
  const [room, setRoom] = useState('');
  const [hex] = useState(random.toString('hex'));

  useEffect(() => {
    hashString(hex).then(hash => {
      setRoom(hash);
      channel.emit(JOIN_CHANNEL, { room: hash });
    });
    channel.on(SET_NETWORK, ({ network }) => {
      setNetwork(network);
    });
  }, []);

  if (!network) {
    return (
      <div className='flex flex-row'>
        <div className='bg-background align-center pl-20 pr-[6vw] py-[7vh] h-screen'>
          <h1 className='text-4xl mb-4'>Welcome,</h1>
          <p className='text-4xl mb-4'>Use Keeper on your computer</p>
          <p className='text-md mb-6 py-10 text-lg'>
            ⚠️ Important Update
            <br/><br/>Keeper Web Interface now available as Desktop App. We've transitioned the Keeper Web
            Interface to a new desktop application for enhanced security and performance.
            <br/><br/>To download the Keeper Desktop App, visit the following link.
            <br/><a href="https://github.com/bithyve/bitcoin-keeper/releases/download/v1.2.10/Bitcoin_Keeper_Desktop_Companion.beta_v0.1.0.dmg">Download</a>
          </p>
          <p className='text-md mb-6 py-5 text-lg'>
            Download and install the latest version compatible with your oprating system. Thank you for your continued support.
            If you have any questions, please don't hesitate to coontact our support team.
          </p>
          <p className='text-md mb-6 py-5 text-lg'>
            This improved version:
            <ol className='list-decimal list-inside mb-6'>
              <li className='w-100'>
                Supports adding, registration and signing with trezor, bitbox and ledger
              </li>
              <li className='w-100'>
                All communications over an end to end encrypted channel
              </li>
            </ol>
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className='flex h-screen flex-col bg-background'>
      <Tabs activeDevice={activeDevice} setActiveDevice={setActiveDevice} />
      {activeDevice === '1' ? (
        <Bitbox02 room={room} network={network} encryptionKey={hex} />
      ) : activeDevice === '2' ? (
        <Trezor room={room} network={network} encryptionKey={hex} />
      ) : (
        <Ledger room={room} network={network} encryptionKey={hex} />
      )}
    </div>
  );
}

export default App;
