import Screen from 'components/Screen';
import * as Button from 'components/Button';
import { useEffect, useState } from 'react';
import { TREZOR_STATUS_MAP } from '../constants';
import useTrezor from 'hooks/useTrezor';
import { getXpub, signTransaction } from 'bridge/trezor';
import { channel } from 'socket';
import {
  SIGNED_TX,
  TREZOR_HEALTHCHECK,
  TREZOR_SETUP,
  TREZOR_SIGN,
} from 'socket/constants';
import { ToastContainer } from 'react-toastify';
import { notifyError, notifySuccess } from 'notify';
import { decrypt, encrypt } from 'utils';
const NOTIFY_DELAY = 2000;

function Trezor({ room, network, encryptionKey }) {
  const { status, init, connect } = useTrezor();
  const [sharingXpub, setSharing] = useState(false);
  const [checkingHealth, setCheckingHealth] = useState(false);
  const [signing, setSigning] = useState(false);

  const shareXpub = async (isHealthCheck = false) => {
    try {
      isHealthCheck ? setCheckingHealth(true) : setSharing(true);
      const data = await getXpub(network);
      channel.emit(isHealthCheck ? TREZOR_HEALTHCHECK : TREZOR_SETUP, {
        room,
        data: encrypt(JSON.stringify(data), encryptionKey),
      });
      isHealthCheck ? setCheckingHealth(false) : setSharing(false);
      notifySuccess(
        `Trezor details shared successfully! You should see the device ${
          isHealthCheck ? 'health updated' : 'added'
        } on the Keeper app now.`
      );
    } catch (err) {
      console.log(err);
    }
  };

  const initiateSigning = () => {
    setSigning(true);
    channel.emit(TREZOR_SIGN, { room });
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (network) {
      channel.on(TREZOR_SIGN, async ({ data, room }) => {
        const decrypted = decrypt(data, encryptionKey);
        const signedTx = await signTransaction(decrypted, network);
        const { success, payload } = signedTx;
        if (success) {
          channel.emit(SIGNED_TX, {
            room,
            data: encrypt(JSON.stringify(payload), encryptionKey),
          });
          setSigning(false);
          notifySuccess('Transaction signed successfully!');
        } else {
          const { error } = payload as { error: string; code?: string };
          notifyError(error.toString());
        }
      });
    }
  }, [network]);

  return (
    <Screen>
      <div className='grid grid-cols-1 gap-10 h-full place-content-center place-items-center'>
        <div className='flex flex-col items-center justify-center h-20 w-80 bg-status rounded'>
          <p>{'Status'}</p>
          <p
            className={
              'text-' + TREZOR_STATUS_MAP[status] + ' font-bold'
            }>{`Device ${status[0].toUpperCase() + status.slice(1)}`}</p>
        </div>
        <div className='flex flex-col items-center'>
          {status === 'disconnected' ? (
            <div className='grid gap-4 grid-cols-1'>
              <Button.Primary onClick={connect}>
                Connect to Trezor
              </Button.Primary>
            </div>
          ) : null}
          {status === 'connected' ? (
            <div className='grid gap-4 grid-cols-1'>
              <Button.Primary onClick={() => shareXpub()} loading={sharingXpub}>
                SHARE XPUB
              </Button.Primary>
              <Button.Primary
                onClick={() => shareXpub(true)}
                loading={checkingHealth}>
                HEALTH CHECK
              </Button.Primary>
              <Button.Primary onClick={initiateSigning} loading={signing}>
                SIGN PSBT
              </Button.Primary>
            </div>
          ) : null}
        </div>
      </div>
      <ToastContainer
        position='top-center'
        autoClose={NOTIFY_DELAY}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover
        theme='light'
      />
    </Screen>
  );
}

export default Trezor;
