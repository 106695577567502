import { toast } from 'react-toastify';

const notifySuccess = (message: string, NOTIFY_DELAY = 3000) => {
  toast.success(message, {
    position: 'top-center',
    autoClose: NOTIFY_DELAY,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: 'light',
  });
};
const notifyInfo = (message: string, NOTIFY_DELAY = 3000) => {
  toast.info(message, {
    position: 'top-center',
    autoClose: NOTIFY_DELAY,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: 'light',
  });
};

const notifyError = (message: string, NOTIFY_DELAY = 3000) => {
  toast.error(message, {
    position: 'top-center',
    autoClose: NOTIFY_DELAY,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: 'light',
  });
};

export { notifySuccess, notifyInfo, notifyError };
