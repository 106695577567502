export enum ScriptTypes {
  'P2PKH' = 'P2PKH', // legacy
  'P2SH-P2WSH' = 'P2SH-P2WSH', // multisig wrapped segwit
  'P2WSH' = 'P2WSH', // multisig native segwit
  'P2SH-P2WPKH' = 'P2SH-P2WPKH', // singlesig wrapped segwit
  'P2WPKH' = 'P2WPKH', // singlesig native segwit
  'P2TR' = 'P2TR', // Taproot
}

export enum NetworkType {
  TESTNET = 'TESTNET',
  MAINNET = 'MAINNET',
}

export enum SignerType {
  BITBOX02 = 'BITBOX02',
  TREZOR = 'TREZOR',
  LEDGER = 'LEDGER',
}
