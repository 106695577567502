const Tab = ({ openTab, setOpenTab, text, index }) => {
  return (
    <li className='flex-auto text-center'>
      <a
        className={
          'text-xs font-bold uppercase p-3 px-10 block leading-normal rounded ' +
          (openTab === String(index)
            ? 'bg-tab'
            : 'text-dullBrown bg-transparent')
        }
        onClick={e => {
          e.preventDefault();
          setOpenTab(String(index));
          localStorage.setItem('active', String(index));
        }}
        href='#link1'>
        {text}
      </a>
    </li>
  );
};
const Tabs = ({ activeDevice, setActiveDevice }) => {
  return (
    <div className='flex flex-row justify-center align-center'>
      <ul className='bg-tabBg flex flex-wrap flex-row mt-20 p-2 rounded'>
        <Tab
          openTab={activeDevice}
          setOpenTab={setActiveDevice}
          text={'Bitbox02'}
          index={1}
        />
        <Tab
          openTab={activeDevice}
          setOpenTab={setActiveDevice}
          text={'Trezor'}
          index={2}
        />
        <Tab
          openTab={activeDevice}
          setOpenTab={setActiveDevice}
          text={'Ledger'}
          index={3}
        />
      </ul>
    </div>
  );
};

export default Tabs;
