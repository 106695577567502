import { NetworkType, ScriptTypes } from 'enums';

const cryptoJS = require('crypto');
const algorithm = 'aes-256-cbc';

const getDerivationPath = (
  scriptType: ScriptTypes,
  network: NetworkType,
  account = 0
) => {
  const networkType = network === NetworkType.TESTNET ? 1 : 0;
  switch (scriptType) {
    case ScriptTypes.P2WSH: // multisig native segwit
      return `m/48'/${networkType}'/${account}'/2'`;
    case ScriptTypes.P2WPKH: // singlesig native segwit
      return `m/84'/${networkType}'/${account}'`;
    case ScriptTypes['P2SH-P2WPKH']: // singlesig wrapped segwit
      return `m/49'/${networkType}'/${account}'`;
    case ScriptTypes['P2SH-P2WSH']: // multisig wrapped segwit
      return `m/48'/${networkType}'/${account}'/1'`;
    case ScriptTypes.P2TR: // Taproot
      return `m/86'/${networkType}'/${account}'`;
    default: // multisig wrapped segwit
      return `m/48'/${networkType}'/${account}'/2'`;
  }
};

const encrypt = (data: string, password: string) => {
  const iv = cryptoJS.randomBytes(16);
  // Creating Cipheriv with its parameter
  const cipher = cryptoJS.createCipheriv(
    algorithm,
    Buffer.from(password, 'hex'),
    iv
  );

  // Updating text
  let encrypted = cipher.update(data);

  // Using concatenation
  encrypted = Buffer.concat([encrypted, cipher.final()]);

  // Returning iv and encrypted data
  return { iv: iv.toString('hex'), encryptedData: encrypted.toString('hex') };
};

const hashString = async data => {
  const hash = await crypto.subtle.digest(
    'SHA-256',
    new TextEncoder().encode(data)
  );
  return Buffer.from(hash).toString('hex');
};

export const decrypt = (
  data: { iv: string; encryptedData: string },
  password: string
) => {
  const encryptedText = Buffer.from(data.encryptedData, 'hex');
  // Creating Decipher
  const decipher = cryptoJS.createDecipheriv(
    algorithm,
    Buffer.from(password, 'hex'),
    Buffer.from(data.iv, 'hex')
  );
  // Updating encrypted text
  let decrypted = decipher.update(encryptedText);
  decrypted = Buffer.concat([decrypted, decipher.final()]);
  // Returning iv and encrypted data
  return JSON.parse(decrypted.toString());
};

export { getDerivationPath, encrypt, hashString };
