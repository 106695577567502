import { NetworkType, ScriptTypes } from 'enums';
import { getDerivationPath } from 'utils';
import AppBtc from '@ledgerhq/hw-app-btc';
import type Transport from '@ledgerhq/hw-transport';
import { AppClient, DefaultWalletPolicy, WalletPolicy } from 'ledger-bitcoin';
import { handleError } from 'hooks/useLedger';

const getXpub = async (ledger: Transport, network) => {
  try {
    const singleSigPath = getDerivationPath(ScriptTypes.P2WPKH, network);
    const multiSigPath = getDerivationPath(ScriptTypes.P2WSH, network);
    const currency =
      network === NetworkType.MAINNET ? 'bitcoin' : 'bitcoin_testnet';
    const xpubVersion = network === NetworkType.MAINNET ? 76067358 : 70617039;
    const appBtc = new AppBtc({ transport: ledger, currency });
    const singleSigXpub = await appBtc.getWalletXpub({
      path: singleSigPath,
      xpubVersion,
    });
    const multiSigXpub = await appBtc.getWalletXpub({
      path: multiSigPath,
      xpubVersion,
    });
    const xfp = await new AppClient(ledger).getMasterFingerprint();
    return {
      singleSigPath,
      multiSigPath,
      singleSigXpub,
      multiSigXpub,
      xfp,
    };
  } catch (e) {
    handleError(e);
    return false;
  }
};

const registerMultisig = async (ledger, data, network) => {
  try {
    const { vault } = data;
    const app = new AppClient(ledger);
    const walletPolicy = await getWalletPolicy(vault);
    const [policyId, policyHmac] = await app.registerWallet(walletPolicy);
    return { policyId, policyHmac: policyHmac.toString('hex') };
  } catch (e) {
    handleError(e);
    return false;
  }
};

const getWalletPolicy = async vault => {
  const { signers, isMultiSig, scheme } = vault;
  const path = `${
    signers[0].masterFingerprint
  }${signers[0].derivationPath.slice(signers[0].derivationPath.indexOf('/'))}`;
  const walletPolicy = !isMultiSig
    ? new DefaultWalletPolicy('wpkh(@0/**)', `[${path}]${signers[0].xpub}`)
    : new WalletPolicy(
        'Keeper Vault',
        `wsh(sortedmulti(${scheme.m},${signers
          .map((_, index) => `@${index}/**`)
          .join(',')}))`,
        signers.map(signer => {
          const path = `${signer.masterFingerprint.toLowerCase()}${signer.derivationPath.slice(
            signer.derivationPath.indexOf('/')
          )}`;
          return `[${path}]${signer.xpub}`;
        })
      );
  return walletPolicy;
};

const signTransaction = async (ledger, data) => {
  try {
    const { serializedPSBT, vault, registeredWallet } = data;
    const btcClient = new AppClient(ledger);
    const walletPolicy = await getWalletPolicy(vault);
    const multisigConfig = registeredWallet
      ? Buffer.from(registeredWallet, 'hex')
      : undefined;
    const result = await btcClient.signPsbt(
      serializedPSBT,
      walletPolicy,
      multisigConfig
    );
    return result;
  } catch (e) {
    handleError(e);
    return false;
  }
};

export { getXpub, registerMultisig, signTransaction };
