import 'react-toastify/dist/ReactToastify.css';
import { getXpub, registerMultisig, signTransaction } from 'bridge/bitbox02';
import * as Button from 'components/Button';
import Screen from 'components/Screen';
import useBitbox from 'hooks/useBitbox';
import { BITBOX02_STATUS_MAP } from '../constants';
import { channel } from 'socket';
import {
  BITBOX_HEALTHCHECK,
  BITBOX_REGISTER,
  BITBOX_SETUP,
  BITBOX_SIGN,
  SIGNED_TX,
} from 'socket/constants';
import { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { decrypt, encrypt } from 'utils';
const NOTIFY_DELAY = 2000;

function Bitbox02({ room, network, encryptionKey }) {
  const { bitbox02, status, pairingCode, connect } = useBitbox();
  const [sharingXpub, setSharing] = useState(false);
  const [checkingHealth, setCheckingHealth] = useState(false);
  const [registering, setRegistering] = useState(false);
  const [signing, setSigning] = useState(false);

  const notify = (message: string) => {
    toast.success(message, {
      position: 'top-center',
      autoClose: NOTIFY_DELAY,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: 'light',
    });
  };
  const inform = (message: string) => {
    toast.info(message, {
      position: 'top-center',
      autoClose: NOTIFY_DELAY,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: 'light',
    });
  };
  const sendDeviceData = async (isHealthCheck = false) => {
    isHealthCheck ? setCheckingHealth(true) : setSharing(true);
    const data = await getXpub(bitbox02, network);
    channel.emit(isHealthCheck ? BITBOX_HEALTHCHECK : BITBOX_SETUP, {
      room,
      data: encrypt(JSON.stringify(data), encryptionKey),
    });
    isHealthCheck ? setCheckingHealth(false) : setSharing(false);
    notify(
      `Bitbox02 details shared successfully! You should see the device ${
        isHealthCheck ? 'health updated' : 'added'
      } on the Keeper app now.`
    );
  };

  const initiateRegistering = async () => {
    setRegistering(true);
    channel.emit(BITBOX_REGISTER, { room });
  };

  const initiateSigning = () => {
    setSigning(true);
    channel.emit(BITBOX_SIGN, { room });
  };

  useEffect(() => {
    if (bitbox02 && network) {
      channel.on(BITBOX_REGISTER, async ({ data }) => {
        const decrypted = decrypt(data, encryptionKey);
        await registerMultisig(bitbox02, decrypted, network);
        setRegistering(false);
        notify('Keeper vault registered successfully!');
      });
    }
  }, [bitbox02, network]);

  useEffect(() => {
    if (bitbox02 && network) {
      channel.on(BITBOX_SIGN, async ({ data, room }) => {
        const decrypted = decrypt(data, encryptionKey);
        inform('Please verify the transaction on your Bitbox02...');
        const signedTx = await signTransaction(bitbox02, decrypted, network);
        channel.emit(SIGNED_TX, {
          room,
          data: encrypt(JSON.stringify(signedTx), encryptionKey),
        });
        setSigning(false);
        notify('Transaction signed successfully!');
      });
    }
  }, [bitbox02, network]);

  return (
    <Screen>
      <div className='grid grid-cols-1 gap-10 h-full place-content-center place-items-center'>
        <div className='flex flex-col items-center justify-center h-20 w-80 bg-status rounded'>
          <p>{'Status'}</p>
          <p
            className={
              'text-' + BITBOX02_STATUS_MAP[status] + ' font-bold'
            }>{`Device ${status[0].toUpperCase() + status.slice(1)}`}</p>
        </div>
        <div className='flex flex-col items-center'>
          {status !== 'initialized' ? (
            <div className='grid gap-4 grid-cols-1'>
              <Button.Primary onClick={connect}>
                Connect to Bitbox02
              </Button.Primary>
            </div>
          ) : null}
          {pairingCode && status === 'unpaired' ? (
            <p>
              Check on your Bitbox to verify this pairing code{' '}
              <b>{pairingCode}</b>
            </p>
          ) : null}
        </div>
        {status === 'initialized' ? (
          <div className='grid gap-4 grid-cols-1'>
            <Button.Primary
              onClick={() => sendDeviceData()}
              loading={sharingXpub}>
              SHARE XPUB
            </Button.Primary>
            <Button.Primary
              onClick={() => sendDeviceData(true)}
              loading={checkingHealth}>
              HEALTH CHECK
            </Button.Primary>
            <Button.Primary onClick={initiateRegistering} loading={registering}>
              REGISTER MULTISIG
            </Button.Primary>
            <Button.Primary onClick={initiateSigning} loading={signing}>
              SIGN PSBT
            </Button.Primary>
          </div>
        ) : null}
      </div>
      <ToastContainer
        position='top-center'
        autoClose={NOTIFY_DELAY}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover
        theme='light'
      />
    </Screen>
  );
}

export default Bitbox02;
