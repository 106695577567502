export const BITBOX02_STATUS_MAP = {
  initialized: 'lightGreen',
  connected: 'orange',
  unpaired: 'gray',
  disconnected: 'gray',
  pairingFailed: 'red',
};

export const TREZOR_STATUS_MAP = {
  connected: 'lightGreen',
  disconnected: 'gray',
};

export const LEDGER_STATUS_MAP = {
  connected: 'lightGreen',
  disconnected: 'gray',
};
